export const ENTITY_TYPE = {
  PURCHASE: 'PURCHASE',
  TRANSFER: 'TRANSFER',
  EXPORT: 'EXPORT',
  ORDER: 'ORDER',
  INVOICE: 'INVOICE',
};

export const ExportFileType = {
  PURCHASE: 'PURCHASE',
  TRANSFER: 'TRANSFER',
};

export const ExportFileStates = {
  CREATED: 'CREATED',
  RETRIEVING: 'RETRIEVING',
  SENDING: 'SENDING',
  SENT: 'SENT',
  ERROR: 'ERROR',
};

export const OrderStates = {
  INITIATED: 'INITIATED',
  STARTED: 'STARTED',
  WAITING_CONFIRMATION: 'WAITING_CONFIRMATION',
  PREAUTHORIZATION_KO: 'PREAUTHORIZATION_KO',
  PREAUTHORIZATION_OK: 'PREAUTHORIZATION_OK',
  DUPLICATED_KO: 'DUPLICATED_KO',
  SCORING_KO: 'SCORING_KO',
  ACCEPTED: 'ACCEPTED',
  CLOSED: 'CLOSED',
};

export const PurchaseStates = {
  CREATED: 'CREATED',
  PAYMENT_OK: 'PAYMENT_OK',
  SHARED: 'SHARED',
  PROCESSING_ECARD: 'PROCESSING_ECARD',
  PROCESSING_TRANSFER: 'PROCESSING_TRANSFER',
  PRIMARY_OK: 'PRIMARY_OK',
  PRIMARY_KO: 'PRIMARY_KO',
  DUPLICATED_KO: 'DUPLICATED_KO',
  SCORING_KO: 'SCORING_KO',
  VCP_KO: 'VCP_KO',
  CONNECTOR_KO: 'CONNECTOR_KO',
  COLLECTING: 'COLLECTING',
  RELEASED: 'RELEASED',
  CANCELLED: 'CANCELLED',
  REDIRECTION_LINK_OK: 'REDIRECTION_LINK_OK',
  REDIRECTION_LINK_KO: 'REDIRECTION_LINK_KO',
  BI_CANCELLED: 'BI_CANCELLED',
  BI_KO: 'BI_KO',
  BI_OK: 'BI_OK',
  BI_PENDING: 'BI_PENDING',
  BI_WAITING_DATA: 'BI_WAITING_DATA',
  BI_SCORING_OK: 'BI_SCORING_OK',
  BI_SCORING_KO: 'BI_SCORING_KO',
  CARD_FILTER_KO: 'CARD_FILTER_KO',
  TMX_SCORING_KO: 'TMX_SCORING_KO',
  WORLDCHECK_FILTER_KO: 'WORLDCHECK_FILTER_KO',
  REVENUE_KO: 'REVENUE_KO',
  RULE_ENGINE_FILTERS_KO: 'RULE_ENGINE_FILTERS_KO',
  COVERAGE_KO: 'COVERAGE_KO',
};

export const AcceptanceStates = {
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
  ABANDONED: 'ABANDONED',
  NOT_INITIATED: 'NOT_INITIATED',
  INITIATED: 'INITIATED',
  PENDING: 'PENDING',
};

export const AcceptanceRejectionTypes = {
  TECHNICAL_ERROR: 'TECHNICAL_ERROR',
  PLEDG_REFUSAL: 'PLEDG_REFUSAL',
  NOT_ELIGIBLE: 'NOT_ELIGIBLE',
  CUSTOMER_BANK_REFUSAL: 'CUSTOMER_BANK_REFUSAL',
  MERCHANT_REFUSAL: 'MERCHANT_REFUSAL',
};

export const InvoiceStates = {
  PAID: 'PAID',
  UNPAID: 'UNPAID',
};

export const FILTER_TYPE = {
  DATE: 'date',
  STATE: 'states',
  ACCEPTANCE_STATE: 'acceptance_states',
  ACCEPTANCE_REJECTION_TYPE: 'acceptance_rejection_types',
  AMOUNT: 'amount',
  IS_PURCHASE_LINK: 'is_purchase_link',
};

// eslint-disable-next-line
const { INITIATED, STARTED: OrderSTARTED, ...DefaultOrderStates } = OrderStates;

export const DefaultFilters = {
  [ENTITY_TYPE.ORDER]: {
    categories: [FILTER_TYPE.STATE],
    queryParameters: [[FILTER_TYPE.STATE, Object.values(DefaultOrderStates).join()]],
  },
};

export const TransferOrderStates = {
  PENDING: 'PENDING',
  WAITING_SELENIUM_NOTIFICATION: 'WAITING_SELENIUM_NOTIFICATION',
  SENT: 'SENT',
  NOT_SENT: 'NOT_SENT',
  PROCESSING_ERROR: 'PROCESSING_ERROR',
  RECEIVED: 'RECEIVED',
};

export const DebitOrderStates = {
  CREATED: 'CREATED',
  KO: 'KO',
  OK: 'OK',
  PENDING: 'PENDING',
  ABORTED: 'ABORTED',
};

export const TransferStates = {
  ...DebitOrderStates,
  ...TransferOrderStates,
};

export const TransferTypes = {
  DEBIT: 'DEBIT',
  TRANSFER: 'TRANSFER',
};

export const PAYMENT_TYPES = {
  INSTALLMENT: 'INSTALLMENT',
  DEFERRED: 'DEFERRED',
  DOWN_PAYMENT: 'DOWN_PAYMENT',
  REDIRECTION: 'REDIRECTION',
  SPLIT: 'SPLIT',
};
export const SCHEDULER_PAYMENT_TYPES = [PAYMENT_TYPES.INSTALLMENT, PAYMENT_TYPES.DEFERRED];

export const ERRORS = {
  RESOURCE_NOT_ALLOWED: 'resource_not_allowed',
  RESOURCE_NOT_FOUND: 'resource_not_found',
};

export const TECHNICAL_ERRORS = [ERRORS.RESOURCE_NOT_ALLOWED];

export const DEFERRED_TYPES = ['deferred_delay_in_days', 'deferred_day_of_next_month'];

export const Operators = {
  PLEDG: 'PLEDG',
  CACF: 'CACF',
};

/**
 * THEMES
 */
export const THEME_PLEDG = {
  CARD_GRADIENT_CLASSES: 'bg-primary',
  CLASS_NAME: 'pledg',
  DOMAIN_NAME: 'pledg.co',
  LOGO_ALT: 'Pledg logo',
  LOGO_FILENAME: 'logo_pledg.png',
  MENU_ICON: 'icon_pledg_white.png',
  PUBLIC_FOLDER: 'pledg',
  TITLE: 'Pledg - Merchant Dashboard',
};

export const THEME_CACF = {
  CARD_GRADIENT_CLASSES: 'bg-primary',
  CLASS_NAME: 'cacf',
  DOMAIN_NAME: 'pledg.co',
  LOGO_ALT: 'Sofinco logo',
  LOGO_FILENAME: 'logo_pledg_by_sofinco.png',
  MENU_ICON: 'icon_sofinco_white.png',
  PUBLIC_FOLDER: 'cacf',
  TITLE: 'Sofinco - Merchant Dashboard',
};

export const THEME_CARREFOUR = {
  CARD_GRADIENT_CLASSES: 'bg-primary bg-gradient-to-bl from-blue-500',
  CLASS_NAME: 'carrefour_banque_et_assurance',
  DOMAIN_NAME: 'carrefour-banque-et-assurance.dashboard.pledg.co',
  LOGO_ALT: 'Carrefour banque et assurance logo',
  LOGO_FILENAME: 'logo_carrefour_banque_et_assurance.png',
  MENU_ICON: 'icon_carrefour_banque_et_assurance.png',
  PUBLIC_FOLDER: 'carrefour_banque_et_assurance',
  TITLE: 'Carrefour banque et assurance - Dashboard',
};

export const THEMES = [THEME_CACF, THEME_CARREFOUR, THEME_PLEDG];

export const THEME_BY_OPERATOR = {
  [Operators.PLEDG]: THEME_PLEDG,
  [Operators.CACF]: THEME_CACF,
};

export const ShareTypes = {
  LEADER: 0,
  PLEDGER: 1,
  FIRST_INSTALLMENT: 2,
  NEXT_INSTALLMENT: 3,
  REDIRECTION: 6,
  DEFERRED: 9,
  DEPOSIT: 10,
  BALANCE: 11,
  ANCV: 12,
};

export const SEARCH_FILTER_KEYS = {
  EMAIL: 'email',
  FIRSTNAME: 'firstname',
  LASTNAME: 'lastname',
  PHONE: 'phone',
  PURCHASE_UID: 'purchase',
  ORDER_UID: 'order',
  REF: 'ref',
  STATEMENT_DESCRIPTOR: 'statement',
  TITLE: 'title',
  TRANSFER_DEBIT_UID: 'transfer',
};

export const PURCHASE_SEARCH_FILTERS = [
  { key: SEARCH_FILTER_KEYS.EMAIL, example: 'john.doe@pledg.co' },
  { key: SEARCH_FILTER_KEYS.FIRSTNAME, example: 'Jules' },
  { key: SEARCH_FILTER_KEYS.LASTNAME, example: 'Martinez' },
  { key: SEARCH_FILTER_KEYS.PHONE, example: '663558607' },
  {
    key: SEARCH_FILTER_KEYS.PURCHASE_UID,
    example: 'pur_5029d73b-2971-4649-baee-68358150e947',
  },
  { key: SEARCH_FILTER_KEYS.REF, example: 'PLEDG_123456' },
  { key: SEARCH_FILTER_KEYS.TITLE, example: 'iphone' },
];

export const TRANSFER_SEARCH_FILTERS = [
  {
    key: SEARCH_FILTER_KEYS.PURCHASE_UID,
    example: 'pur_5029d73b-2971-4649-baee-68358150e947',
  },
  { key: SEARCH_FILTER_KEYS.REF, example: 'PLEDG_123456' },
  { key: SEARCH_FILTER_KEYS.STATEMENT_DESCRIPTOR, example: 'DB15B170416D5240C08744C0F3DEA4B654' },
  {
    key: SEARCH_FILTER_KEYS.TRANSFER_DEBIT_UID,
    example: 'tr_b76b935e-734e-4b98-9329-4b61c8049b98',
  },
];

export const ORDER_SEARCH_FILTERS = [
  { key: SEARCH_FILTER_KEYS.EMAIL, example: 'john.doe@pledg.co' },
  { key: SEARCH_FILTER_KEYS.FIRSTNAME, example: 'Jules' },
  { key: SEARCH_FILTER_KEYS.LASTNAME, example: 'Martinez' },
  { key: SEARCH_FILTER_KEYS.PHONE, example: '663558607' },
  {
    key: SEARCH_FILTER_KEYS.ORDER_UID,
    example: 'ord_4029d73b-2971-4649-baee-68358150e642',
  },
  { key: SEARCH_FILTER_KEYS.REF, example: 'PLEDG_123456' },
];

export const PURCHASE_AVAILABILITY = {
  IN_STOCK: 'in_stock',
  OUT_OF_STOCK: 'out_of_stock',
};

export const Authorizations = {
  DASHBOARD__MERCHANTS__VIEW: 'DASHBOARD__MERCHANTS__VIEW',
  DASHBOARD__PURCHASES__VIEW: 'DASHBOARD__PURCHASES__VIEW',
  DASHBOARD__PURCHASES__REFUND: 'DASHBOARD__PURCHASES__REFUND',
  DASHBOARD__ORDERS__VIEW: 'DASHBOARD__ORDERS__VIEW',
  DASHBOARD__TRANSFERS__VIEW: 'DASHBOARD__TRANSFERS__VIEW',
  DASHBOARD__EXPORTED_FILES__VIEW: 'DASHBOARD__EXPORTED_FILES__VIEW',
  DASHBOARD__SETTINGS__VIEW: 'DASHBOARD__SETTINGS__VIEW',
  DASHBOARD__SETTINGS__EDIT: 'DASHBOARD__SETTINGS__EDIT',
  DASHBOARD__SETTINGS__ACCESS_SECRET_KEY: 'DASHBOARD__SETTINGS__ACCESS_SECRET_KEY',
  DASHBOARD__COLLABORATORS__VIEW: 'DASHBOARD__COLLABORATORS__VIEW',
  DASHBOARD__COLLABORATORS__EDIT: 'DASHBOARD__COLLABORATORS__EDIT',
  DASHBOARD__DOCUMENTS__VIEW: 'DASHBOARD__DOCUMENTS__VIEW',
  DASHBOARD__ESSENTIEL_PAYMENT_TRIGGER: 'DASHBOARD__ESSENTIEL_PAYMENT_TRIGGER',
  DASHBOARD__ESSENTIEL_RELEASE_TRIGGER: 'DASHBOARD__ESSENTIEL_RELEASE_TRIGGER',
  PURCHASE_LINK__ACCESS: 'PURCHASE_LINK__ACCESS',
  KEY_INDICATORS_ACCESS: 'KEY_INDICATORS_ACCESS',
  USER_SETTINGS__ACCESS: 'USER_SETTINGS__ACCESS',
  USER_SETTINGS__COLLABORATORS__EDIT: 'USER_SETTINGS__COLLABORATORS__EDIT',
};

export const Profiles = {
  READER: 'READER',
  SELLER: 'SELLER',
  MANAGER: 'MANAGER',
  ACCOUNTANT: 'ACCOUNTANT',
  ADMIN: 'ADMIN',
};

export const Notify = {
  NEVER: 'never',
  AUTO: 'auto',
  ALWAYS: 'always',
};

export const DocumentTypes = {
  INVOICE: 'invoice',
  CREDIT_NOTE: 'credit_note',
};

export const TAG_SIZE = {
  SMALL: 'small',
  MEDIUM: 'medium',
  MEDIUM_STRICT: 'medium_strict',
  LARGE: 'large',
};

export const AnonymizedFields = {
  EMAIL: '******@***',
  PHONE_NUMBER: '**********',
  ADDRESS: '**********',
};
