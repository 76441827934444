import { createRouter, createWebHistory } from 'vue-router';
import { TransferTypes } from '@/constants';
import { authInstance } from '@/plugins/auth';
import { isKpiOnlyUser } from '@/helpers/authorizations';

const Home = () => import(/* webpackChunkName: "landing" */ '@/views/Home.vue');
const Login = () => import(/* webpackChunkName: "landing" */ '@/views/Login.vue');

const Dashboard = () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue');
const NoMerchant = () => import(/* webpackChunkName: "dashboard" */ '@/views/NoMerchant.vue');
const MerchantsExport = () =>
  import(/* webpackChunkName: "dashboard" */ '@/views/MerchantsExport.vue');
const MerchantSettings = () =>
  import(/* webpackChunkName: "dashboard" */ '@/views/MerchantSettings.vue');
const MerchantCollaborators = () =>
  import(/* webpackChunkName: "dashboard" */ '@/views/MerchantCollaborators.vue');
const MerchantPurchases = () =>
  import(/* webpackChunkName: "dashboard" */ '@/views/MerchantPurchases.vue');
const PurchaseDetails = () =>
  import(/* webpackChunkName: "dashboard" */ '@/views/PurchaseDetails.vue');
const MerchantOrders = () =>
  import(/* webpackChunkName: "dashboard" */ '@/views/MerchantOrders.vue');
const OrderDetails = () => import(/* webpackChunkName: "dashboard" */ '@/views/OrderDetails.vue');
const Logout = () => import(/* webpackChunkName: "dashboard" */ '@/views/Logout.vue');
const MerchantTransfers = () =>
  import(/* webpackChunkName: "dashboard" */ '@/views/MerchantTransfers.vue');
const TransferDetails = () =>
  import(/* webpackChunkName: "dashboard" */ '@/views/TransferDetails.vue');
const MerchantPurchaseLink = () =>
  import(/* webpackChunkName: "dashboard" */ '@/views/MerchantPurchaseLink.vue');
const NotFoundPage = () => import(/* webpackChunkName: "landing" */ '@/views/NotFoundPage.vue');
const KeyIndicators = () => import(/* webpackChunkName: "dashboard" */ '@/views/KeyIndicators.vue');
const Documents = () => import(/* webpackChunkName: "dashboard" */ '@/views/Documents.vue');
const DocumentsBilling = () =>
  import(/* webpackChunkName: "dashboard" */ '@/views/DocumentsBilling.vue');
const UserSettings = () => import(/* webpackChunkName: "dashboard" */ '@/views/UserSettings.vue');
const UserSettingsCollaborators = () =>
  import(/* webpackChunkName: "dashboard" */ '@/views/UserSettingsCollaborators.vue');
const UserSettingsCompanies = () =>
  import(/* webpackChunkName: "dashboard" */ '@/views/UserSettingsCompanies.vue');

const redirectIfAuthenticated = (to, from, next) => {
  // redirect user if already authenticated
  if (authInstance.check()) {
    next({ name: 'dashboard' });
    return;
  }
  next();
};

const BreakException = {};

const redirectToNotFound =
  (params = []) =>
  (to, from, next) => {
    try {
      /**
       * When the expected route parameter is
       * an UID: the parameter must be present in the route payload
       * an ID: the parameter must be present in the route payload, and must be an integer
       * */
      params.forEach((param) => {
        if (!to.params[param]) {
          throw BreakException;
        }
      });
      next();
    } catch (e) {
      if (e !== BreakException) throw e;
      next({ name: 'not-found' });
    }
  };

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      layout: 'landing',
    },
    beforeEnter: redirectIfAuthenticated,
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: { layout: 'landing' },
    beforeEnter: redirectIfAuthenticated,
  },
  {
    path: '/logout',
    name: 'logout',
    component: Logout,
    meta: { auth: true, layout: 'blank', backgroundColor: 'primary' },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: { auth: true, layout: 'merchants' },
    beforeEnter: (to, from, next) => {
      if (isKpiOnlyUser(authInstance?.user())) {
        next({ name: 'key-indicators' });
        return;
      }
      next();
    },
    children: [
      {
        path: 'merchants/home',
        name: 'no-merchant',
        component: NoMerchant,
        meta: { layout: 'merchants' },
      },
      {
        path: 'merchants/:uid/purchases',
        name: 'merchant-purchases',
        component: MerchantPurchases,
        meta: { search: true, selectMultiple: true, layout: 'merchants' },
      },
      {
        path: 'merchants/:uid/purchases/:purchaseUid',
        name: 'merchant-purchase-detail',
        component: PurchaseDetails,
        meta: {
          search: true,
          selectMultiple: true,
          fallbackRoute: 'merchant-purchases',
          layout: 'merchants',
        },
        beforeEnter: redirectToNotFound(['uid', 'purchaseUid']),
      },
      {
        path: 'merchants/:uid/orders',
        name: 'merchant-orders',
        component: MerchantOrders,
        meta: { search: true, selectMultiple: true, layout: 'merchants' },
      },
      {
        path: 'merchants/:uid/orders/:orderUid',
        name: 'merchant-order-detail',
        component: OrderDetails,
        meta: {
          search: true,
          selectMultiple: true,
          fallbackRoute: 'merchant-orders',
          layout: 'merchants',
        },
        beforeEnter: redirectToNotFound(['uid', 'orderUid']),
      },
      {
        path: 'merchants/:uid/transfers',
        name: 'merchant-transfers',
        component: MerchantTransfers,
        meta: { search: true, selectMultiple: true, layout: 'merchants' },
        beforeEnter: redirectToNotFound(['uid']),
      },
      {
        path: 'merchants/:uid/transfers/:transferUid',
        name: 'merchant-transfer-detail',
        component: TransferDetails,
        props: { transferType: TransferTypes.TRANSFER },
        meta: {
          search: true,
          selectMultiple: true,
          fallbackRoute: 'merchant-transfers',
          layout: 'merchants',
        },
        beforeEnter: redirectToNotFound(['uid', 'transferUid']),
      },
      {
        path: 'merchants/:uid/debits/:transferUid',
        name: 'merchant-debit-detail',
        component: TransferDetails,
        props: { transferType: TransferTypes.DEBIT },
        meta: {
          search: true,
          selectMultiple: true,
          fallbackRoute: 'merchant-transfers',
          layout: 'merchants',
        },
        beforeEnter: redirectToNotFound(['uid', 'transferUid']),
      },
      {
        path: 'merchants/:uid/exports',
        name: 'merchant-exports',
        component: MerchantsExport,
        meta: { search: true, selectMultiple: true, layout: 'merchants' },
        beforeEnter: redirectToNotFound(['uid']),
      },
      {
        path: 'merchants/:uid/settings',
        name: 'merchant-settings',
        component: MerchantSettings,
        meta: { search: true, layout: 'merchants' },
        beforeEnter: redirectToNotFound(['uid']),
      },
      {
        path: 'merchants/:uid/collaborators',
        name: 'merchant-collaborators',
        component: MerchantCollaborators,
        meta: { search: true, layout: 'merchants' },
        beforeEnter: redirectToNotFound(['uid']),
      },
    ],
  },
  {
    path: '/purchase-link',
    name: 'purchase-link',
    component: MerchantPurchaseLink,
    meta: { auth: true, layout: 'merchants' },
  },
  {
    path: '/key-indicators',
    name: 'key-indicators',
    component: KeyIndicators,
    meta: { auth: true, layout: 'default', title: 'key_indicators.title' },
  },
  {
    path: '/documents',
    name: 'documents',
    redirect: '/documents/billing',
    component: Documents,
    meta: { auth: true, layout: 'default', title: 'documents.title' },
    children: [
      {
        path: 'billing',
        name: 'documents-billing',
        component: DocumentsBilling,
        meta: {
          layout: 'default',
          title: 'documents.title',
        },
      },
    ],
  },
  {
    path: '/user-settings',
    name: 'user-settings',
    redirect: '/user-settings/companies',
    component: UserSettings,
    meta: { auth: true, layout: 'default', title: 'user_settings.title' },
    children: [
      {
        path: 'collaborators',
        name: 'settings-collaborators',
        component: UserSettingsCollaborators,
        meta: { layout: 'default', title: 'user_settings.title' },
      },
      {
        path: 'companies',
        name: 'settings-companies',
        component: UserSettingsCompanies,
        meta: {
          layout: 'default',
          title: 'user_settings.title',
        },
      },
    ],
  },
  {
    name: 'not-found',
    path: '/:pathMatch(.*)*',
    meta: {},
    component: NotFoundPage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
