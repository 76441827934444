const _cypressEnv = (property) => {
  if (typeof Cypress === 'undefined') return;
  // eslint-disable-next-line
  return Cypress.env()[property];
};

const _env = (property) => process.env[property];

const getEnvValue = (property) => _env(property) || _cypressEnv(property);

export const VUE_APP_VERSION = getEnvValue('VUE_APP_VERSION');

export const VUE_APP_I18N_LOCALE = getEnvValue('VUE_APP_I18N_LOCALE');

export const VUE_APP_I18N_FALLBACK_LOCALE = getEnvValue('VUE_APP_I18N_FALLBACK_LOCALE');

export const VUE_APP_ECARD_BACK_URL = getEnvValue('VUE_APP_ECARD_BACK_URL');

export const VUE_APP_ECARD_BACK_API_SUFFIX = getEnvValue('VUE_APP_ECARD_BACK_API_SUFFIX');

export const VUE_APP_ECARD_BACK_RESET_PASSWORD_SUFFIX = getEnvValue(
  'VUE_APP_ECARD_BACK_RESET_PASSWORD_SUFFIX',
);

export const VUE_APP_PLEDG_ENV = getEnvValue('VUE_APP_PLEDG_ENV');
export const VUE_APP_LOG_LEVEL = getEnvValue('VUE_APP_LOG_LEVEL');
export const VUE_APP_ZENDESK_KEY = getEnvValue('VUE_APP_ZENDESK_KEY');

export const isProductionEnv = VUE_APP_PLEDG_ENV === 'production';
export const IS_PRODUCTION = _env('NODE_ENV') === 'production';

export const TEST_MERCHANT_REGULAR_USER = getEnvValue('TEST_MERCHANT_REGULAR_USER');
export const TEST_MERCHANT_REGULAR_PASSWORD = getEnvValue('TEST_MERCHANT_REGULAR_PASSWORD');
export const TEST_MERCHANT_ADMIN_USER = getEnvValue('TEST_MERCHANT_ADMIN_USER');
export const TEST_MERCHANT_ADMIN_PASSWORD = getEnvValue('TEST_MERCHANT_ADMIN_PASSWORD');
export const TEST_MERCHANT_PLEDG_OPERATOR_USER = getEnvValue('TEST_MERCHANT_PLEDG_OPERATOR_USER');
export const TEST_MERCHANT_PLEDG_OPERATOR_PASSWORD = getEnvValue(
  'TEST_MERCHANT_PLEDG_OPERATOR_PASSWORD',
);
export const TEST_MERCHANT_READER_USER = getEnvValue('TEST_MERCHANT_READER_USER');
export const TEST_MERCHANT_READER_PASSWORD = getEnvValue('TEST_MERCHANT_READER_PASSWORD');
export const TEST_MERCHANT_SELLER_USER = getEnvValue('TEST_MERCHANT_SELLER_USER');
export const TEST_MERCHANT_SELLER_PASSWORD = getEnvValue('TEST_MERCHANT_SELLER_PASSWORD');
export const TEST_MERCHANT_SUPERUSER_USER = getEnvValue('TEST_MERCHANT_SUPERUSER_USER');
export const TEST_MERCHANT_SUPERUSER_PASSWORD = getEnvValue('TEST_MERCHANT_SUPERUSER_PASSWORD');
export const TEST_MERCHANT_KPI_ONLY_USER = getEnvValue('TEST_MERCHANT_KPI_ONLY_USER');
export const TEST_MERCHANT_KPI_ONLY_PASSWORD = getEnvValue('TEST_MERCHANT_KPI_ONLY_PASSWORD');
